<template>
  <main class="page-points page-notes-point p-0 d-flex flex-column">
    <div class="nav-container">
      <div class="container">
        <ul class="nav nav-pills nav-fill">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'points.show' }">Dati</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'points.edit' }">Modifica</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'points.pick' }">Punto</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :class="{ 'disabled': !point.hasContours }" :to="{ name: 'points.contours' }">Contorni</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link active" :to="{ name: 'points.notes' }" aria-current="page">Contenuti</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="flex-grow-1 container py-5">
      <div class="nav nav-pills tutorial-nav" id="nav-vertical-tab" role="tablist" aria-orientation="vertical" :class="notes.length > 0 ? 'justify-content-between' : ''">
        <div class="d-flex">
          <a v-for="(note, i) in notes" class="nav-link" :class="{ 'active': !newNote && selectedNoteId === note.id }" id="nav-vertical-tab1-tab" data-toggle="tab" @click.prevent="editNote(note.id)" role="tab" aria-controls="nav-vertical-tab1" aria-selected="true" :key="note.id">
            Contenuto <span class="tutorial-number">#{{ i + 1 }}</span>
          </a>
        </div>
        <a class="nav-link m-0 add-new" :class="{ 'active': newNote }" @click.prevent="addNote">Aggiungi</a>
        <a class="nav-link m-0 preview" :class="{ 'active': $modals.preview }" @click.prevent="$openModal('preview')">Preview</a>
      </div>
      <div class="tab-content mt-5" v-if="newNote">
        <NoteForm :feedback.sync="feedback" @submitForm="saveNewNote" />
      </div>
      <div class="tab-content mt-5" v-else-if="note">
        <NoteForm :feedback.sync="feedback" edit :note="note" @submitForm="updateNote" @removeNote="removeActiveNote" />
      </div>
      <div class="tab-content mt-5 text-center" v-else>
        <template v-if="notes.length === 0">Aggiungi un nuovo contenuto</template>
        <template v-else>Seleziona un contenuto esistente o aggiungine uno nuovo</template>
      </div>
    </div>
    <InfoModal v-if="$modals.preview" modal-class="modal-preview" :title="point.windowTitle" @close="$closeModal('preview')">
      <h3>{{ point.title }}</h3>
      <div class="mb-4" :class="{ 'd-flex flex-row': previewTemplateId === 1 }" v-for="note in notes" :key="note.id">
        <div class="d-flex flex-row align-items-center justify-content-between" :class="{ 'w-50': previewTemplateId === 1 }">
          <img v-for="image in note.images" :style="{ 'max-width': previewTemplateId === 2 ? ('calc(' + (100 / note.images.length) + '% - 20px)') : '100%' }" :src="image.url" :key="image.id" alt="">
        </div>

        <p :class="{ 'w-50 ml-4': previewTemplateId === 1, 'mt-3': previewTemplateId === 2 }">{{ note.description }}</p>
      </div>
    </InfoModal>
  </main>
</template>

<script>

import rolesMixin from '@/mixins/roles';
import confirmWithModalMixin from '@/libs/Confirm/mixins/confirmWithModal';
import modalsManagerMixin from '@/libs/Modals/mixins/manager.js';
import $api from '@/libs/OAuth2/services/api.js';
import InfoModal from '@/libs/Modals/components/InfoModal.vue';

export default {
  mixins: [rolesMixin, confirmWithModalMixin, modalsManagerMixin],
  components: {
    NoteForm: () => import('@/views/components/Form/NoteForm'),
    InfoModal,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    module: {
      type: Object,
      required: true,
    },
    point: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      feedback: null,
      newNote: null,
      selectedNoteId: null,
      notes: [],
    };
  },
  computed: {
    note () {
      return this.notes.find(n => n.id === this.selectedNoteId);
    },
    previewTemplateId () {
      if (this.point.templateId === 2) {
        return 2;
      }

      let templateId = 1;

      this.notes.forEach(n => {
        if (n.images.length > 1) {
          templateId = 2;
        }
      });

      return templateId;
    },
  },
  methods: {
    fetchResults () {
      return $api.fetchNotes(this.point.id)
        .then(res => {
          this.notes = res?.data?.notes || [];
        })
        .catch(() => this.$log.error)
      ;
    },
    editNote (id) {
      this.selectedNoteId = id;
      this.feedback = null;
      this.newNote = null;
    },
    addNote () {
      this.newNote = {
        description: null,
        images: [],
      };
    },
    saveNewNote (noteForm) {
      const note = { ...noteForm };

      this.$api.createNote(this.point.id, note)
        .then(() => {
          this.feedback = true;
          return this.fetchResults();
        })
        .then(() => {
          this.editNote(null);
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
    updateNote (noteForm) {
      const note = { ...noteForm };

      this.$api.updateNote(note.id, note)
        .then(() => {
          this.feedback = true;
          return this.fetchResults();
        })
        .then(this.abortEditNote)
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
    removeActiveNote () {
      this.confirm('Cancellare il contenuto e tutte le immagini collegate?', 'Sì', 'No')
        .then(confirmed => {
          if (!confirmed) {
            return;
          }

          if (!this.note) {
            return;
          }

          this.$api.removeNote(this.note.id)
            .then(() => {
              return this.fetchResults();
            })
            .then(() => {
              this.editNote(null);
            })
            .catch(this.$log.errors)
          ;
        })
      ;
    },
    abortEditNote () {
      this.editNote(null);
    },
  },
  mounted () {
    this.fetchResults();
  },
};

</script>

<style>

.modal--type-info.modal-preview {
  max-width: 66vw;
}

</style>
